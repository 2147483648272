.navbar {
  width: 100%;
  left: 0;
  top: 0;
  z-index: 22;
  color: $light;
  height: 100px;
  padding: 0;
  @extend %tr02;
  position: absolute;

  &.is-active,
  &.is-actived {
    background-color: $dark2;
    z-index: 33;
  }
  & > .container {
    height: 100%;
  }
  &-nav {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 100%;
  }

  .nav {
    &-item {
      margin: 0 25px;
      height: 100%;

      @include mq("desktop", max) {
        margin: 0 15px;
      }

      &:not(.nav-item-dropdown):hover,
      &:not(.nav-item-dropdown).active {
        .nav-link {
          // border-bottom-color: $green;
          color: $green;
        }
      }

      &-dropdown {
        .nav-link > i {
          margin-left: 10px;
        }
      }

      .dropdown {
        &-content {
          opacity: 0;
          visibility: hidden;
          padding: 30px 0;
          width: 100%;
          position: absolute;
          background-color: $dark2;
          left: 0;
          top: 100px;
          border-top: 2px solid $dark1;

          .btn-close {
            position: absolute;
            right: 20px;
            font-size: 28px;
            color: $light;
            cursor: pointer;
            top: 20px;
            z-index: 22;
          }

          @extend %tr02;

          .container {
            width: 100%;
            .row {
              width: 100%;
              margin: 0;

              & > div {
                padding-left: 0;
              }
            }
          }
          p {
            font-size: 14px;
            text-transform: uppercase;
            color: $white;
          }

          ul {
            padding: 0;
            list-style-type: none;
            li {
              margin: 7.5px 0;
              a {
                color: #5a5a5a;
                font-size: 1rem;
                &:hover {
                  color: $white;
                }
              }
            }
          }

          @include mq("tablet-wide", max) {
            top: 0;
          }
        }
      }
      &.is-active {
        .dropdown {
          &-content {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    &-link {
      // border-bottom: 1px solid transparent;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .lang {
    &-content {
      opacity: 0;
      visibility: hidden;
      padding: 50px 0;
      width: 100%;
      position: absolute;
      background-color: $dark2;
      left: 0;
      top: 100px;
      border-top: 2px solid $dark1;

      .btn-close {
        position: absolute;
        right: 20px;
        font-size: 28px;
        color: $light;
        cursor: pointer;
        top: 20px;
      }

      &.is-actived {
        opacity: 1;
        visibility: visible;
      }
    }

    &-items {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      a {
        display: inline-block;
        margin: 0 20px;
        padding: 15px;
        border: 1px solid transparent;

        &.active {
          border-color: $light;
        }

        &:not(.active):hover {
          color: $green;
        }
      }
    }
  }

  .open-menu {
    position: absolute;
    z-index: 22;
    color: $light;
    width: 40px;
    font-size: 28px;
    margin-top: 4px;
    right: 5px;

    i {
      font-size: 28px;
      @extend %tr02;
    }

    @include mq("tablet-wide", min) {
      display: none;
    }
  }

  .socials {
    display: none;
    a {
      margin: 10px 0;
      color: #565656;
      font-size: 24px;

      &:hover {
        color: $green;
      }
    }
  }

  @include mq("tablet-wide", max) {
    height: 70px;
    &-nav {
      top: 0;
      left: 0;
      position: absolute;
      flex-direction: column;
      background-color: $dark2;
      background: radial-gradient(#181818, $dark2);

      justify-content: flex-start;
      align-items: flex-start;
      height: 100vh;
      width: 100%;

      padding: 90px 15px 30px;
      opacity: 0;
      visibility: hidden;
      transition: all 0.2s ease 0s;
      &.is-active {
        opacity: 1;
        visibility: visible;
      }
    }

    .nav {
      &-item {
        height: auto;
        margin: 0;
        margin-bottom: 15px;
      }

      &-link {
        height: auto;
        text-align: left;
        margin: 0;
        padding-top: 0;
      }
    }

    .phone {
      opacity: 0;
      visibility: hidden;

      &.is-active {
        opacity: 1;
        visibility: visible;
        position: absolute;
        top: 70vh;
      }
    }

    .dropdown-content {
      height: 100vh;
      overflow-y: auto;
      z-index: 99;
      background: radial-gradient(#181818, $dark2);

      .container > .row {
        & > div {
          &:first-of-type {
            margin-bottom: 20px;
          }
        }
      }
    }

    .lang-content {
      opacity: 0;
      visibility: hidden;

      &.is-active {
        opacity: 1;
        visibility: visible;
        position: absolute;
        top: 87vh;
        padding: 0;
        border-top: none;
        .btn-close {
          display: none;
        }
      }

      .lang-items {
        a {
          margin: 0 5px;
          padding: 10px;
        }
      }
    }

    .container {
      padding: 0;
    }

    .socials {
      display: block;

      opacity: 0;
      visibility: hidden;
      padding: 0;
      flex-direction: row;
      a {
        margin: 0;
        margin-left: 25px;
        color: $light;
      }
      &.is-active {
        z-index: 22;
        opacity: 1;
        visibility: visible;
        position: absolute;
        top: 70vh;
        padding: 0;
        border-top: none;
        right: 25px;
        .btn-close {
          display: none;
        }
      }
    }
  }

  @include mq("tablet", max) {
    .container {
      padding: 0 1rem;
    }
    .dropdown-content {
      height: auto;
      max-height: 100vh;
    }
  }
}

.navbar-left {
  position: absolute;
  z-index: 23;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 120px;
  height: 100vh;

  border-right: 2px solid $dark1;

  .socials {
    padding: 40px 0;
    display: flex;
    flex-direction: column;

    a {
      margin: 10px 0;
      color: #565656;
      font-size: 24px;

      &:hover {
        color: $green;
      }
    }

    @include mq("tablet-wide", max) {
      display: none;
    }
  }

  @include mq("desktop", max) {
    width: 100px;
    border-right: none;
  }
  @include mq("tablet-wide", max) {
    z-index: 22;
    width: 0;
    .lang {
      display: none;
    }
  }

  & + .navbar-top {
    .navbar-brand {
      @include mq("desktop-wide", max) {
        transform: translateX(60px);
      }
      @include mq("desktop", max) {
        transform: translateX(70px);
      }
      @include mq("tablet-wide", max) {
        transform: translateX(0px);
      }
    }
  }
}

.navbar-top {
  position: relative;
  border-bottom: 1px solid #1e1e1e;
  .navbar-brand {
    img {
      max-width: 100px;
    }
  }
  @include mq("tablet-wide", max) {
    .navbar-brand {
      z-index: 44;
      position: absolute;
      left: 15px;
    }
  }
}

.navbar-left ~ .navbar-top {
  position: absolute;
  border-bottom: 0;
}
