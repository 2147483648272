footer {
  position: relative;
  .navbar {
    position: relative;
    bottom: 0;
    color: #707070;

    & .nav-item:not(.nav-item-dropdown):hover .nav-link {
      color: $white;
    }

    & .nav-item:not(.nav-item-dropdown).active .nav-link {
      color: $white;
    }

    &-brand {
      transform: unset !important;
      img {
        max-width: 100%;
      }
    }

    &-nav {
      @include mq("desktop", max) {
        display: none !important;
      }
    }

    @include mq("desktop", max) {
      height: auto;
      padding: 15px 0;
    }
    @include mq("tablet", max) {
      text-align: center;

      .container {
        display: flex;
        flex-direction: column;
      }
      &-brand {
        margin: 0px auto 5px;
      }
    }
  }

  .navbar .nav-item .dropdown-content {
    bottom: 100px;
    top: unset;
  }
}
