// .modal {
//   /* width */
//   &::-webkit-scrollbar {
//     width: 8px;
//     height: 4px;
//   }

//   /* Track */
//   &::-webkit-scrollbar-track {
//     background-color: #07060b;
//   }

//   /* Handle */
//   &::-webkit-scrollbar-thumb {
//     background-color: $green;
//     border-radius: 6px;
//   }

//   /* Handle on hover */
//   &::-webkit-scrollbar-thumb:hover {
//     background-color: $green;
//     border-radius: 6px;
//   }
// }

.modal {
  &-video {
    background-color: $dark2;
    .modal {
      &-content,
      &-body {
        background-color: transparent;
        border: 0;
        iframe,
        video {
          width: 100%;
          height: 600px;

          @include mq("tablet", max) {
            height: 300px;
          }
        }

        .close {
          color: white;
          font-size: 28px;

          position: absolute;
          top: -30px;
          right: -50px;

          @include mq("tablet", max) {
            right: 15px;
          }
        }
      }
    }
  }

  &-price-list {
    background-color: $dark2;
    .modal {
      &-dialog {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        max-width: unset;
      }

      &-content,
      &-body {
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: 0;

        h4 {
          color: $light;
        }

        display: flex;
        justify-content: center;
        align-items: center;
        .close {
          color: white;
          font-size: 28px;

          position: absolute;
          top: 30px;
          right: 30px;

          @include mq("tablet", max) {
            right: 15px;
          }
        }
      }
    }
  }

  &-success {
    .modal {
      &-dialog {
        max-width: 400px;
      }
      &-content,
      &-body {
        border: 0;
        background-color: $dark1;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;

        p {
          font-size: 24px;
          color: #5e5e5e;
          margin: 20px 0;
        }

        img {
          max-width: 80px;
        }
      }
      &-body {
        padding: 50px 30px;
      }
    }
  }
}
