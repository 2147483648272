.form {
  input,
  textarea {
    border: 1px solid #2c2c2c;
    padding: 15px 15px;
    background-color: transparent;
    color: $white;
    font-size: 16px;
    display: block;
    width: 100%;
    @extend %tr02;
    &:focus {
      border-color: $white;
    }
    resize: none;
  }
}
