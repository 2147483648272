header {
  background-image: url("../img/header-bg.jpg");
  // background: radial-gradient(rgba($dark1, 0.98), $dark2);
  background-color: #0c0c0c;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  @include mq("tablet-wide", min) {
    .container {
      height: 100%;
      .row {
        height: 100%;
        & > div {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          &:last-of-type {
            align-items: flex-start;
          }
        }
      }
    }
  }

  h1 {
    font-weight: bold;
    font-size: 54px;
    color: white;
    span {
      display: inline;
      color: $green;
      font-size: 24px;
    }
  }

  p {
    font-size: 18px;
    color: $light;
    margin: 15px 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  @include mq("desktop", max) {
    h1 {
      font-size: 42px;
    }
  }

  @include mq("tablet-wide", max) {
    h1 {
      font-size: 34px;
    }
  }

  @include mq("tablet", max) {
    position: relative;
    .container {
      height: 100%;
      .row {
        height: 100%;
        & > div {
          &:first-of-type {
            display: flex;
            justify-content: center;
            padding-top: 35px;
          }

          &:last-of-type {
            margin-top: -30px;
          }
        }
      }
    }
    h1 {
      font-size: 32px;

      span {
        font-size: 16px;
      }
    }

    img {
      max-width: 300px;
      object-fit: contain;
    }

    .btn {
      margin-top: 1rem !important;
      //   display: block;
      //   margin-left: auto;
      //   margin-right: auto;
    }
  }

  @include mq("phone-small", max) {
    h1 {
      font-size: 24px;

      span {
        font-size: 14px;
      }
    }

    p {
      font-size: 14px;
    }

    img {
      max-width: 180px;
    }
  }
}
@media only screen and (max-width: 376px) {
  header {
    height: 675px;
  }
}

@media only screen and (max-width: 321px) {
  header {
    height: 575px;
  }
}
