@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/ProximaNova-Black.eot");
  src: local("☺"), url("../fonts/ProximaNova-Black.woff") format("woff"),
    url("../fonts/ProximaNova-Black.ttf") format("truetype"),
    url("../fonts/ProximaNova-Black.svg") format("svg");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/ProximaNova-Bold.eot");
  src: local("☺"), url("../fonts/ProximaNova-Bold.woff") format("woff"),
    url("../fonts/ProximaNova-Bold.ttf") format("truetype"),
    url("../fonts/ProximaNova-Bold.svg") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/ProximaNova-Extrabold.eot");
  src: local("☺"), url("../fonts/ProximaNova-Extrabold.woff") format("woff"),
    url("../fonts/ProximaNova-Extrabold.ttf") format("truetype"),
    url("../fonts/ProximaNova-Extrabold.svg") format("svg");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/ProximaNova-Light.eot");
  src: local("☺"), url("../fonts/ProximaNova-Light.woff") format("woff"),
    url("../fonts/ProximaNova-Light.ttf") format("truetype"),
    url("../fonts/ProximaNova-Light.svg") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/ProximaNova-Regular.eot");
  src: local("☺"), url("../fonts/ProximaNova-Regular.woff") format("woff"),
    url("../fonts/ProximaNova-Regular.ttf") format("truetype"),
    url("../fonts/ProximaNova-Regular.svg") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/ProximaNova-Semibold.eot");
  src: local("☺"), url("../fonts/ProximaNova-Semibold.woff") format("woff"),
    url("../fonts/ProximaNova-Semibold.ttf") format("truetype"),
    url("../fonts/ProximaNova-Semibold.svg") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/ProximaNova-Thin.eot");
  src: local("☺"), url("../fonts/ProximaNova-Thin.woff") format("woff"),
    url("../fonts/ProximaNova-Thin.ttf") format("truetype"),
    url("../fonts/ProximaNova-Thin.svg") format("svg");
  font-weight: 100;
  font-style: normal;
}
