.btn {
  border-radius: 0;
  &-outline-light {
    font-size: 14px;
    color: $light;
    padding: 15px 15px;
    border-color: $light;
    text-transform: uppercase;

    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus {
      background-color: $green;
      color: $white;
      border-color: $green;
      box-shadow: none;
    }

    &:not(:disabled):not(.disabled).active {
      &:focus {
        background-color: $green;
        color: $white;
        border-color: $green;
        box-shadow: none;
      }
      background-color: $green;
      color: $white;
      border-color: $green;
      box-shadow: none;
    }
  }
}
