.phone {
  a {
    display: block;

    &:hover {
      color: $green;
    }
  }
}

.lang {
  background-color: $dark1;
  width: 100%;
  height: 120px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  @include mq("desktop", max) {
    height: 100px;
  }
}

.breadcrumb {
  padding: 25px 0;
  background-color: transparent;
  margin: 0;

  &-item {
    a {
      color: #5e5e5e;
    }

    &.active {
      color: $white;
    }

    & + &::before {
      content: "-";
    }
  }
}

.pagination {
  margin-top: 20px;
  
  .page {
    &-item {
      margin-left: 10px;
      &.active {
        .page-link {
          border-color: white;
          background-color: transparent;
        }
      }

      &:first-child {
        margin-left: 0;
      }

      &:first-child,
      &:last-child {
        .page-link {
          border-radius: 0;
        }
      }
    }
    &-link {
      background-color: transparent;
      border-color: #39383c;
      color: white;
      border-radius: 0;

      box-shadow: none !important;
    }
  }
}
