.product,
.news {
  color: $light;
  &-header {
    height: 200px;
    width: 100%;

    @include mq("tablet-wide", max) {
      height: 250px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &-body {
    padding: 18px;
    background-color: $dark2;
  }

  &-title {
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: $white;
  }

  &-info {
    p {
      margin-bottom: 5px;
      font-size: 14px;
    }
  }

  .btn {
    width: 100%;
    margin-top: 20px;
  }
}

.feature {
  color: $white;
  &-item {
    display: flex;
    align-items: center;

    &:not(:nth-of-type(2)) {
      .feature-content {
        text-align: right;
      }
      .feature-img {
        text-align: left;
      }
    }

    &:nth-of-type(2) {
      border-top: 2px solid #262626;
      border-bottom: 2px solid #262626;

      .feature-img {
        text-align: right;
      }

      .feature-circle {
        position: relative;
        &::before {
          content: url("../img/turn-right-arrow.svg");
          position: absolute;
          right: -22px;
          top: -22px;
          width: 30px;
          height: 45px;
          display: block;
        }
        &::after {
          content: url("../img/turn-left-arrow.svg");
          position: absolute;
          left: -22px;
          bottom: -22px;
          width: 30px;
          height: 45px;
          display: block;
        }
      }
    }

    @include mq("tablet", max) {
      flex-wrap: wrap;
    }
  }

  hr {
    border-color: #868687;
    border-width: 2px;
  }

  &-content,
  &-img {
    width: calc((100% - 110px) / 2);
    padding: 15px 50px;
  }

  &-img {
    img {
      max-width: 200px;
      height: auto;
    }
  }

  &-circle {
    width: 110px;
    align-self: stretch;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 2px solid #262626;
    border-right: 2px solid #262626;
  }

  &-title {
    font-size: 22px;
    font-weight: bold;
  }

  &-subtitle {
    font-size: 14px;
    color: $light;
    margin-bottom: 0;
  }

  @include mq("tablet-wide", max) {
    &-title {
      font-size: 16px;
    }

    &-subtitle {
      font-size: 12px;
    }
  }

  @include mq("tablet", max) {
    &-title {
      font-size: 18px;
    }

    &-subtitle {
      font-size: 14px;
    }
    &-img {
      display: none;
    }

    &-content {
      width: 70%;
      padding: 25px 0 25px 15px;
      text-align: left !important;
    }

    &-circle {
      width: 30%;
      border: 0;
      order: -1;
      img {
        max-width: 100%;
      }

      &::after,
      &::before {
        display: none !important;
      }
    }
  }
}

.address {
  margin-bottom: 30px;
  &-title {
    color: $white;
    font-size: 14px;
    margin-bottom: 10px;
  }

  &-content {
    a[href^="tel:"] {
      color: $white;
      margin-right: 10px;
    }

    a[href^="tel:"],
    a[href^="mailto:"] {
      &:hover {
        color: $green;
      }
    }
  }

  .socials {
    display: flex;
    a {
      color: #565656;
      font-size: 24px;
      padding: 10px;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid transparent;

      &:hover {
        border-color: $white;
        color: $white;
      }
    }
  }
}

.news-item {
  img {
    width: 100%;
    height: auto;
  }

  &-content {
    padding: 30px 0 0px;
    h3 {
      color: white;
      font-weight: bold;
      font-size: 30px;
    }

    p {
      color: $light;
      font-size: 16px;
    }
  }
}

.vacancy {
  &-item {
    margin-bottom: 30px;
    background-color: $dark1;
    padding: 30px;
    color: white;

    ul,
    ol {
      padding-left: 15px;
      margin: 0;

      li {
        color: #868687;
      }
    }

    p {
      margin: 20px 0 10px;
    }

    h5 {
      font-size: 20px;
      margin: 30px 0 20px;
    }

    h4 {
      font-size: 24px;
      font-weight: 400;
      color: $green;
    }

    @include mq("tablet", max) {
      padding: 30px 20px;
    }
  }
}
