body {
  background-color: #07060b;
}

.section-products,
.section-news {
  padding: 70px 0 20px;

  hr {
    border-color: $dark2;
    border-width: 2px;
  }
}

.section-banner {
  padding: 30px 0;
}

.section-about {
  padding: 60px 0 90px;
  @include mq("tablet", max) {
    padding: 30px 0 60px;
  }
  .container {
    position: relative;
  }

  .col-md-7 {
    @include mq("tablet", max) {
      position: relative;
      z-index: 22;
    }
  }

  .col-md-6 {
    position: absolute;
    right: 15px;
    z-index: 12;
    top: 50%;
    transform: translateY(-50%);
    background-color: $dark1;
    padding: 30px;

    @include mq("tablet", max) {
      position: relative;
      width: 100%;
      top: unset;
      transform: unset;
      right: unset;
      margin: 35px 15px 90px;
      order: -1;
    }
  }

  .about-us {
    position: relative;
    &::before {
      content: url("../img/leaf1.png");
      width: 131px;
      height: 131px;
      position: absolute;
      z-index: -1;
      right: -105px;
      top: -110px;
      display: block;
    }
    &::after {
      content: url("../img/leaf2.png");
      width: 131px;
      height: 131px;
      position: absolute;
      z-index: -1;
      right: -105px;
      bottom: -110px;
      display: block;
    }

    @include mq("desktop", max) {
      &::after,
      &::before {
        right: -40px;
      }
    }
    // @include mq("tablet", max) {
    //   &::before {
    //     // right: unset;
    //     // left: -30px;
    //   }
    // }

    color: $white;
    h4 {
      font-size: 24px;
      font-weight: bold;
    }

    hr {
      border-color: #262626;
      border-width: 2px;
    }

    &-content {
      color: $light;
      font-size: 14px;
      padding-right: 5px;
      max-height: 248px;
      overflow-y: auto;

      /* width */
      &::-webkit-scrollbar {
        width: 8px;
        height: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background-color: $green;
        border-radius: 6px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background-color: $green;
        border-radius: 6px;
      }
    }
  }

  .video-img {
    border: 0;
    width: 100%;
    height: 500px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      @extend %tr02;
    }
    &:hover {
      img {
        transform: scale(1.2);
      }
    }

    @include mq("tablet", max) {
      height: 300px;
    }
  }
}

.section-feature {
  background-image: url("../img/feature-bg.jpg");
  background-color: #0c0c0c;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 60px 0;

  @include mq("tablet", max) {
    padding: 30px 0;
  }
}

.section-form {
  padding: 60px 0;

  h2,
  p {
    color: #5e5e5e;
  }

  @include mq("tablet", max) {
    padding: 30px 0;
  }
}

.section-map {
  & > div,
  iframe {
    width: 100%;
    height: 450px;
    border: 0;
    opacity: 0.4;
  }
}

.section-news {
  padding: 0 0 30px;

  .news {
    &-header {
      height: 250px;
    }

    &-info {
      @include text-clamp(3, 65px);
    }
  }
}

.section-product-item {
  .product {
    &-img {
      img {
        width: 100%;
        height: 450px;
        object-fit: cover;
        object-position: center;

        @include mq("tablet", max) {
          height: 350px;
        }
      }
    }
    &-title {
      font-size: 24px;
    }

    .btn {
      width: auto;
    }
  }
}

.section-404 {
  background-image: url("../img/404.jpg");
  // background: radial-gradient(rgba($dark1, 0.98), $dark2);
  background-color: #0c0c0c;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 70px;
    color: $white;
    margin-top: 12px;
  }

  a {
    position: absolute;
    bottom: 150px;
    left: 52%;
    transform: translateX(-50%);
    text-transform: uppercase;
    color: $white;

    img {
      margin-left: 10px;
      transform: translateY(-3px);
    }
  }

  @include mq("tablet", max) {
    h1 {
      font-size: 45px;
    }
  }
}
