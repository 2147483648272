html {
  scroll-behavior: smooth !important;
}

* {
  outline: none !important;
  &::selection {
    background-color: #28b873;
    color: white;
  }
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

button:focus {
  outline: none !important;
  outline: 0 auto -webkit-focus-ring-color;
}

.form-control:focus {
  outline: 0;
  box-shadow: none;
}

.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label,
.input-group > .custom-select:focus,
.input-group > .form-control:focus {
  z-index: 0;
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0;
}

a {
  @extend %tr02;
  text-decoration: none;
  color: inherit;

  &:hover {
    @extend %tr02;
    text-decoration: none;
    color: inherit;
  }
}

body {
  font-family: "ProximaNova", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  position: relative;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
    height: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: #07060b;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: $green;
    border-radius: 6px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: $green;
    border-radius: 6px;
  }

  &.is-active,
  &.is-actived {
    &::after {
      position: absolute;
      content: "";
      background-color: rgba($color: #000000, $alpha: 0.5);
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    @include mq("tablet-wide", max) {
      overflow: hidden;
    }
  }
  &.is-actives {
    @include mq("tablet-wide", max) {
      overflow: hidden;

      &::after {
        position: absolute;
        content: "";
        background-color: rgba($color: #000000, $alpha: 0.5);
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  &.is-active2 {
    &::after {
      position: absolute;
      content: "";
      background-color: rgba($color: #000000, $alpha: 0.5);
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    @include mq("tablet-wide", max) {
      overflow: hidden;
    }
  }
}

// @media (min-width: 1200px) {
//   .container,
//   .container-lg,
//   .container-md,
//   .container-sm,
//   .container-xl {
//     max-width: 1280px;
//   }
// }
