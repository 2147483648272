.swiper-pagination-bullet {
  height: 5px;
  width: 25px;
  border-radius: 2.5px;
  background-color: rgba($color: $white, $alpha: 0.5);
  opacity: 1;
  &-active {
    background-color: #c2ae83;
  }
}

.section-products {
  .swiper-products {
    padding-top: 75px;
    margin-top: -50px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    top: 10px;

    &::after {
      color: white;
    }
  }
  .swiper-button-prev {
    left: unset;
    right: 65px;

    &::after {
      content: url("../img/prev-arrow.svg");
    }
  }
  .swiper-button-next {
    &::after {
      content: url("../img/next-arrow.svg");
    }
  }
}

.swiper-certificate {
  a {
    img {
      @include mq("tablet-wide", min) {
        max-width: 200px;
      }
      max-width: 100%;
    }
  }
}
